
<template>


    <div class="article-nav wow slideInRight">

        <div class="article-nav-lianxi">
            <ul>
                <li>
                    <div>
                        <span class="iconfont">&#xe60c;</span>
                        <span>微信</span>
                        <span>mrzhao520</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span class="iconfont">&#xe600;</span>
                        <span>微博</span>
                        <span>mrzhao520</span>
                    </div>
                </li>
                <li>
                    <div>
                        <span class="iconfont">&#xe60d;</span>
                        <span>QQ</span>
                        <span>780027387</span>
                    </div>
                </li>
            </ul>
        </div>


        <div class="nav-box">
            <div class="article-nav-link">
                <div class="sub-article-nav-box">
                    <div class="nav-title-box">
                        <div class="nav-link-title">
                            <div class="sub-nav-title">{{ article_class_data[article_class] }}文章分类</div>
                        </div>
                    </div>


                    <slot></slot>


                </div>
            </div>
        </div>

        <div class="img-box">
            <div class="article-nav-img">
                <div v-for="(item, index) in img_list" class="img-obj-box">
                    <span>
                        <img :src="item.src" alt="">
                    </span>
                </div>
            </div>
        </div>


        <div class="article-new-box">
            <div class="article-new">
                <div class="sub-article-nav-box">
                    <div class="nav-title-box">
                        <div class="nav-link-title">
                            <div class="sub-nav-title">最新文章</div>
                        </div>
                    </div>
                    <div class="article-link-list">
                        <ul>
                            <li v-for="item in new_article">
                                <div>
                                    <div class="article-title-box"><a :href="'/article/' + item.article_id">{{ item.title }}</a></div>
                                </div>
                                <div>
                                    <div class="article-time-box"><span>发布日期：{{ item.article_time }}</span></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import {WOW} from 'wowjs'
export default {
    props: ['article_class', ],
    name: "ArticleNav",
    data() {
        return {
            img_list: [],

            article_class_data: {'17': '技术', '16': '生活', '18': ''},
            new_article: [],


            data: [],
        }
    },

    mounted(){
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: false,
            live: false,
        });
        wow.init();
    },

    created() {
        let that = this;
        let newArticleData = {};
        if (that.article_class) {
            newArticleData['article_class'] = that.article_class;
        }

        that.axios.get('/new_article', {params: newArticleData})
            .then(function (response) {
                that.new_article = response.data.data_list;
            })
            .catch(function (error) {
                ElMessage({
                    showClose: true,
                    message: '文章列表获取失败，请刷新',
                    type: 'error'
                })
            });



        for (let i=1;i <= 8;i++){
            that.img_list.push({src: `${this.$imgUrl}/home/small_img/` + i + '.jpg'})
        }





    },

    watch: {
        // red_class: function () {
        //     this.$emit('getRedClass', this.red_class);
        // }
    },


}
</script>


<!--四个模块的盒子-->
<style>
@keyframes example {
    from {
        transform: translate(100px);
    }
    to {
        transform: translate(0);
    }
}

.article-nav {
    /*animation: example 0.5s linear 1;*/
    width: 25%;
    /*height: 1160px;*/
    padding: 0 10px;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*flex-direction: column;*/
}

.article-nav-lianxi, .nav-box, .img-box{
    margin-bottom: 20px;
}
</style>


<!--联系模块-->
<style>
.iconfont {
    font-size: 23px;
}

.article-nav-lianxi ul {
    display: flex;
}

.article-nav-lianxi li {
    background-color: #fafafa;
    border: 0.5px solid white;
    width: 33.33333333333333%;
    padding: 20px 0;
}

.article-nav-lianxi li div {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.article-nav-lianxi span {
    display: block;
    margin: 2px 0;
}


@media screen and (min-width: 0px) and (max-width: 990px) {
    .article-nav {
        display: none !important;
    }
}

</style>


<!--文章分类模块-->
<style>

.article-nav-link, .article-new {
    padding: 30px 17px;
    background-color: #fafafa;
}

.nav-link-title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;
}


/*.sub-nav-title{*/
/*    border-bottom: ;*/
/*}*/

.nav-link-list ul {
    display: flex;
    flex-direction: column;
}

.nav-link-list li:hover {
    cursor: pointer;
    color: #e74c3c;
}

.nav-link-list li span {
    font-size: 14px;
    font-weight: normal;
    display: block;
    padding: 10px 0;
}

.el-icon-caret-right {
    font-weight: bold;
}


</style>


<!--8个小图片图片-->
<style>
@keyframes aaa {
    0% {}
    20% {}
    40% {}
    60% {}
    80% {}
    100% {}
}

.article-nav-img {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.img-obj-box {
    width: 24%;
    margin-bottom: 1%;
}
.img-obj-box span{
    display: block;
}


.article-nav-img img {
    width: 100%;
}

</style>


<!--最新文章-->
<style>
.article-link-list li {
    padding: 12px 7px;
    border-top: 1px solid #ebebeb;
}

.article-link-list ul {
    display: flex;
    flex-direction: column;
}

.article-link-list li:hover a {
    color: #e74c3c;
}


.article-title-box {
    color: #333333;
    font-size: 15px;
    padding-bottom: 10px;
}

.article-time-box {
    font-size: 12px;
    color: #999999;
}

.article-nav-img div{
    transition: transform 500ms;
}

.el-tree{
    background-color: transparent;
    color: #666666;
}
.el-tree-node__content>.el-tree-node__expand-icon{
    padding: 0;
}
.el-tree-node__expand-icon{
    font-size: 14px;
    color: #666666;
}
.el-tree-node__content{
    height: 37px;
}

.el-tree-node__content:hover .el-tree-node__label{
    color: #e74c3c;
}

.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    color: #e74c3c;
    background-color: transparent;
}

</style>
